<template>
  <ion-page class="ion-page">
    <GoBackHeader defaultHref='/profile' />

    <ion-content>
      <ion-list v-if="user && user.facilities && user.facilities.length" class="mt-4">
        <ion-item v-for="facility in user.facilities" :key="facility.id" button @click="openFacility(facility.id)">
          <ion-thumbnail slot="start">
            <img :src="`${$apiStorageUrl}/${facility.profile_image}`">
          </ion-thumbnail>
          {{ facility.name }}
        </ion-item>
      </ion-list>

      <PageItem v-else>
        <ion-text>
          {{ $t('notmanagingfacilities') }}
        </ion-text>
      </PageItem>
    </ion-content>
  </ion-page>
</template>

<script>
    import PageItem from '@/components/PageItem'

    import {CURRENT_USER_GETTER} from "@/store/store-getters";

    export default {
      components: {
        PageItem
      },
      methods: {
        openFacility(id) {
          this.$router.push({ name: this.$routeNames.FACILITY, params: { id } })
        }
      },
      computed: {
        user() {
          return this.$store.getters[CURRENT_USER_GETTER]
        }
      }
    }
</script>
